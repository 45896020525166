import * as React from 'react';
import { useState, useRef } from 'react';
import Stack from '@mui/material/Stack';
import useWindowDimensions from '../hooks/useWindowDimensions';

import heading_diana from "./heading_diana.svg"
import customDefines from '../palette';

function StickyHeader(props) {
    const headerRef = useRef();
    const [stickyHeader, setStickyHeader] = useState(false);

    const styles = {
        background: {
            background: "white",
            width: "min(900px, 100%)",
            // height: "100px",
            padding: "20px 8px",
            borderBottom: "2px " + customDefines.primaryColor + " solid",
            zIndex: "100"
        },
        sticky: {
            position: "fixed",
            top: "0px",
            padding: "20px 8px"
        }
    }

    window.onscroll = function () {
        if (window.scrollY > headerRef.current.offsetTop + headerRef.current.clientHeight / 2) {
            setStickyHeader(true);
            props.onStickyChanged(true)
        }
        else {
            setStickyHeader(false);
            props.onStickyChanged(false)
        }
    }

    const defaultStyle = { ...styles.background }
    const style = stickyHeader ? { ...defaultStyle, ...styles.sticky } : { ...defaultStyle }
    const height = headerRef.current == null ? 0 : headerRef.current.clientHeight
    return <React.Fragment>
        <div ref={headerRef} style={style}>
            {props.children}
        </div >
        {stickyHeader ? <div style={{ height: height }}></div> : <div></div>}

    </React.Fragment>
}

function Header() {
    const { width } = useWindowDimensions();
    const mobileView = width < customDefines.mobileWidthThreshold
    const [sticky, setSticky] = useState(false);

    function diana_signature() {
        return <div style={{ padding: "0px 10px" }} > <img alt="Diana" style={{ display: "block", height: "70px", margin: "0px auto" }} src={heading_diana} /></div>;
    }

    const styles = {
        link: {
            fontSize: mobileView ? "85%" : "100%"
        }
    }
    return <StickyHeader onStickyChanged={setSticky}>
        {!sticky && mobileView ? <div style={{ width: "100%", paddingBottom: "20px" }}>{diana_signature()}</div> : <></>}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <a style={styles.link} href="#ueber_mich" key="ueber_mich">ÜBER MICH</a>
            <a style={styles.link} href="#hoerproben" key="hoerproben">HÖRPROBEN</a>
            {!sticky && !mobileView ? diana_signature() : <></>}
            <a style={styles.link} key="q_a" href="#q_a" >Q&A</a>
            <a style={styles.link} key="rezessionen" href="#rezessionen">REZESSIONEN</a>
        </Stack>
    </StickyHeader >
    // if (width < customDefines.mobileWidthThreshold) {
    //     return <MobileHeader></MobileHeader>
    // }
    // else {
    // }
}


export default Header;