import * as React from 'react';
// import ReactPlayer from 'react-player';
import { useRef } from 'react';

import { IconButton, Stack, Slider } from "@mui/material"

import { useState } from 'react';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import LowHighResImage from '../components/lowHighResImage';


function VideoComponent({ video, title, interpret, reportPlayCallback, pauseForced, secondZeroThumbnailLow, secondZeroThumbnailHigh }) {
    let [stateString, setStateString] = useState("paused");  // Valid options are ["paused", "playing"]
    const [maximumTime, setMaximumTime] = useState(0);
    const [timeoutHandle, setTimeoutHandle] = useState(null);
    const [intervalHandle, setIntervalHandle] = useState(null);
    const [controlsVisible, setControlsVisible] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [videoAspect, setVideoAspect] = useState(0);
    const videoRef = useRef(null);

    const [showThumbnail, setShowThumbnail] = useState(true);

    const styles = {
        controlOverlay: {
            position: 'absolute',
            display: 'flex',
            top: '0px',
            bottom: '0px',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        wrapper: {
            position: "relative",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%'
        },
        playPauseIcon: {
            fontSize: 55,
            color: '#fff'
        },
        fullscreenIcon: {
            fontSize: 30,
            color: '#fff'
        },
        titleText: {
            fontSize: "1.25em",
            color: 'white',
            // textShadow: '0px 0px 3px #000, 0px 0px 5px #000'
            // textShadow: "0px 0px 3px #000000"
        },
        interpretText: {
            fontSize: "1em",
            color: 'white',
            paddingLeft: "1vw"
            // textShadow: '0px 0px 3px #000, 0px 0px 5px #000'
            // textShadow: '0px 0px 5px #000, 0px 0px 4px #000, 0px 0px 4px #000'

            // textShadow: "1px 1px 2px #000000"
        },
        filledDiv: {
            display: "block",
            width: "100%",
            height: "100%",
            // background: 'red',
        },
        subTitle: {
            paddingTop: "8px",
            textAlign: "center",
            textTransform: "uppercase",
        },
        subInterpret: {
            textAlign: "center",
            textTransform: "uppercase",
            color: "#555",
            fontSize: "85%",
        },
        fullscreenTitleBackground: {
            background: "linear-gradient(180deg, rgba(0,0,0,0.55) 40%, rgba(0,0,0,0.55) 70%, rgba(0,0,0,0) 100%)",
            height: "max(4em, 12%)",
            width: "100%",
            paddingTop: "1vh",
            paddingLeft: "1vw"
        }
    }

    const pause = () => {
        videoRef.current.pause();
        clearTimeout(timeoutHandle);
        clearInterval(intervalHandle);
        setStateString("paused");
        setControlsVisible(true);
    };

    const setTimeoutControlsVisible = (state) => {
        clearTimeout(timeoutHandle);
        if (state) {
            setControlsVisible(true);
            if (stateString === "playing") {
                setTimeoutHandle(setTimeout(() => {
                    setControlsVisible(false);
                }, 2500));
            }
        }
        else {
            setControlsVisible(false);
        }
    };

    const play = () => {
        if (!videoRef.current) return;

        setShowThumbnail(false);
        stateString = "playing";
        setStateString("playing");
        setTimeoutControlsVisible(true);
        videoRef.current.play();
        setMaximumTime(videoRef.current.duration - 0.01);

        setIntervalHandle(setInterval(() => {
            if (!videoRef.current) return;
            const currentVideoTime = videoRef.current.currentTime;
            if (maximumTime > 0 && currentVideoTime >= maximumTime) {
                pause();
                videoRef.current.currentTime = 0;
            }
            setCurrentTime(currentVideoTime);
        }, 333));
    };

    const playPauseClicked = () => {
        if (stateString === "playing") {
            pause();
        }
        else {
            play();
            reportPlayCallback(video);
        }
    };

    if (stateString === "playing" && pauseForced) {
        pause();
    }

    const fullscreenHandle = useFullScreenHandle();

    return <div>
        <FullScreen handle={fullscreenHandle}>
            <div onDoubleClick={() => { if (fullscreenHandle.active) { fullscreenHandle.exit(); } else { fullscreenHandle.enter() }; setTimeoutControlsVisible(true) }} style={styles.wrapper}>
                {showThumbnail && secondZeroThumbnailLow != null ?
                    <LowHighResImage lowResSrc={secondZeroThumbnailLow} highResSrc={secondZeroThumbnailHigh} alt="thumbnail"></LowHighResImage> : null
                }
                <video preload="metadata" hidden={showThumbnail && secondZeroThumbnailLow} ref={videoRef} onLoadedData={() => { setVideoAspect(100 * videoRef.current.videoHeight / videoRef.current.videoWidth); }} width="100%" height="100%">
                    <source src={video} type="video/mp4">
                    </source>
                </video>
                <div style={styles.controlOverlay}>
                    {(controlsVisible) ?
                        <React.Fragment>
                            {fullscreenHandle.active ?
                                <div style={{ width: videoAspect < 100 ? "100%" : videoAspect + "vh", height: videoAspect < 100 ? videoAspect + "vw" : "100%" }}>
                                    <div style={styles.fullscreenTitleBackground}>
                                        <span style={styles.titleText}>{title}</span>
                                        <span style={styles.interpretText}>{interpret}</span>
                                    </div>
                                </div>
                                : <div></div>
                            }
                            <div style={{ position: "absolute" }}>
                                <IconButton onClick={playPauseClicked}>{stateString === "playing" ? <PauseCircleRoundedIcon style={styles.playPauseIcon} /> : <PlayArrowRoundedIcon style={styles.playPauseIcon} />}</IconButton>
                            </div>
                            {
                                <Stack direction="row" style={{ position: 'absolute', bottom: "2%", width: "100%", paddingLeft: "20px", paddingRight: "15px" }} justifyContent='center' alignItems='center'>
                                    <Slider style={{ marginLeft: "15px" }} min={0} max={maximumTime} value={currentTime} onChange={(event, value) => { setTimeoutControlsVisible(true); videoRef.current.currentTime = value; setCurrentTime(value) }}></Slider>
                                    <IconButton onClick={() => { fullscreenHandle.active ? fullscreenHandle.exit() : fullscreenHandle.enter(); setTimeoutControlsVisible(true); }}>{fullscreenHandle.active ? <FullscreenExitRoundedIcon style={styles.fullscreenIcon} /> : <FullscreenRoundedIcon style={styles.fullscreenIcon} />}</IconButton>
                                </Stack>
                            }
                        </React.Fragment>
                        :
                        <div style={styles.filledDiv} onClick={() => setTimeoutControlsVisible(true)}></div>
                    }
                </div>
            </div>
        </FullScreen >
        <div style={styles.subTitle}>{title}</div>
        <div style={styles.subInterpret}>{interpret}</div>
    </div >
}

export default VideoComponent;