import * as React from "react"

import customDefines from "../palette";

function ColoredBackgroundBox({ color, children }) {
    const styles = {
        background: {
            background: color,
            width: "100%",
            padding: (color === customDefines.brightBackground ? "50px" : "20px") + " max(20px, 5%)"
        },
        content: {
            margin: "auto",
            width: "100%",
        }
    }
    return (
        <div style={styles.background}>
            <div style={styles.content}>
                {children}
            </div>
        </div>
    );
}

export default ColoredBackgroundBox;