import * as React from 'react';

import { Stack } from "@mui/material"

import customDefines from '../palette';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';

import useWindowDimensions from '../hooks/useWindowDimensions';

import FramedImage from '../components/framedImage';

import occasion_trauung_high from '../resources/generated_images/occasion_trauung_high.webp'
import occasion_trauung_low from '../resources/generated_images/occasion_trauung_low.svg'

import occasion_taufe_high from '../resources/generated_images/occasion_taufe_high.webp'
import occasion_taufe_low from '../resources/generated_images/occasion_taufe_low.svg'

function Occasions() {
    const { width } = useWindowDimensions();
    const mobileView = width < customDefines.mobileWidthThreshold
    return (
        <ColoredBackgroundBox color={customDefines.brightBackground}>
            <Stack spacing={2} direction={mobileView ? "column" : "row"} style={{ alignItems: mobileView ? "center" : "normal", justifyContent: "space-between" }} >
                <FramedImage mobileView={mobileView} low={occasion_taufe_low} high={occasion_taufe_high} alt="Taufe" captionSection="Taufe" captionText="">
                    Musikalisches Komplettpaket an der Tauffeier eures Kindes
                </FramedImage>
                <FramedImage mobileView={mobileView} low={occasion_trauung_low} high={occasion_trauung_high} alt="Trauung" captionSection="Trauung" >
                    Gänsehautmomente an eurem schönsten Tag
                </FramedImage>
            </Stack>
        </ColoredBackgroundBox >
    );
}

export default Occasions;