import React from 'react';

import customDefines from '../palette';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';

// import useWindowDimensions from '../hooks/useWindowDimensions';


function Impressum() {
    // const { width } = useWindowDimensions();
    // const mobileView = width < customDefines.mobileWidthThreshold
    return (
        <ColoredBackgroundBox color={customDefines.brightBackground}>
            <div className="extern">
                <h1>Impressum</h1>

                <p>Diana Sonntag<br />
                    Diana Sonntag Musik<br />
                    Kreuter Berg 14<br />
                    86650 Wemding</p>

                <h2>Kontakt</h2>
                <p>Telefon: +49 151 43270850<br />
                    E-Mail: info@diana-sonntag.de</p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
            </div>
        </ColoredBackgroundBox >
    );
}


export default Impressum;
