import * as React from 'react';
import { forwardRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';
import customDefines from '../palette';

const datamodel = [
    {
        question: "Wird das Equipment für den Auftritt mitgebracht? Was muss vor Ort vorhanden sein?",
        answer: "Mein Equipment (Gesangsanlage, Mikrofon, etc.) bringe ich selbst mit. Lediglich eine Sitzgelegenheit und eine normale Steckdose sollten in unmittelbarer Nähe vorhanden sein. Der Strom sollte zum Zeitpunkt des Aufbaus zwecks Soundcheck unbedingt angeschaltet sein. In den meisten Standesämtern und Kirchen ist dies der Fall. Fragt am besten beim Standesbeamten oder Pfarrer kurz nach."
    },
    {
        question: "An unserer Eventlocation gibt es leider keinen Strom, kannst du trotzdem auftreten?",
        answer: "Für diesen speziellen Fall habe ich einen kleinen Akku-Verstärker, der es mir ermöglicht trotzdem verstärkt aufzutreten. Bitte gebt mir bei der Anfrage direkt Bescheid, dass wir diesen speziellen Fall durchsprechen können."
    },
    {
        question: "Wie viele Lieder sollten gesungen werden?",
        answer: "Im Standesamt werden meisten drei Lieder gespielt. Für kirchliche Hochzeiten oder Taufen sind ca. vier Lieder üblich. Ich singe ausschließlich Solostücke, d. h. eine rein instrumentale Begleitung durch Gitarre (z. B. für Ein- oder Auszug) oder eine Begleitung von Gemeinschaftsliedern (z. B. für die Eucharistiefeier) ist nicht möglich. Hierfür empfehle ich einen Organisten."
    },
    {
        question: "Unser Lieblingslied ist nicht in deinem Repertoire, studierst du auch neue Songs ein?",
        answer: "Sehr gerne bereite ich auch andere Lieder für euch vor. Ich freue mich immer über neue Songs 😊."
    },
    {
        question: "Was ist, wenn du krank wirst?",
        answer: "Das ist immer ein Fall, von dem ich hoffe, dass er nie eintritt. Dennoch ist es leider auch menschlich, dass ich krank werden kann und meine Stimme versagt. In diesem Fall versuche ich immer einen adäquaten Ersatz zu finden. Hierbei verfüge ich über eine Reihe von qualifizierten Kontakten, die ich dann informieren werde und alles notwendige zur Verfügung stelle. "
    },
    {
        question: "Warum können wir keine Preisliste auf deiner Webseite finden?",
        answer: "Um einen konkreten Preis für meine Dienstleistung nennen zu können, benötige ich einige individuelle Angaben, wie zum Beispiel den Ort (für Fahrtkosten) und um welchen Anlass es sich handelt. Es ist daher für mich schwierig allgemeingültige und pauschale Preise zu nennen. Bitte habt hierfür Verständnis. Gerne mache ich Ihnen ein individuell passendes Angebot. Scheut euch nicht davor, mich diesbezüglich unverbindlich zu kontaktieren. Viel wichtiger als der richtige Preis ist allerdings, dass euch meine Stimme zusagt und berührt. Schließlich soll eine Hochzeit etwas ganz besonderes werden."
    },
    {
        question: "Gibt es auch die Möglichkeit von Live-Musik für Sektempfang, die Hochzeitsfeier oder andere Anlässe?",
        answer: "Auch diese Möglichkeiten biete ich an. Mit der Coverband „Pluspunkt“ stehen mein Freund Florian und ich auch für Feierlichkeiten, die über mehrere Stunden gehen, zur Verfügung. Weitere Informationen zum Duo findet ihr unter www.pluspunkt-musik.de"
    },
    {
        question: "In welchen Landkreisen trittst du auf?",
        answer: "Mein Aktionsradius liegt bei ca. 70 Kilometern von meinem Wohnort 86650 Wemding."
    },
    {
        question: "Welche Anlässe begleitest du?",
        answer: "Trauungen (standesamtlich, kirchlich, frei), Taufen oder euren ganz besonderen privaten Moment (z. B. Heiratsantrag). Im Duo biete ich die o. g. Anlässe sowie Sektempfang, Nachmittags- und/oder Abendbegleitung an."
    },
]

const FAQ = forwardRef(function FAQ(props, ref) {
    const [expanded, setExpanded] = React.useState("");

    const handleChange =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };


    return (
        <React.Fragment>
            <div id="q_a" ref={ref} style={{ position: "relative", top: "-50px" }}></div>
            <ColoredBackgroundBox color={customDefines.coloredBackground}>
                <h2>Fragen und Antworten</h2>
                {datamodel.map((element, key) => {
                    return (<Accordion key={key} expanded={expanded === key} onChange={handleChange(key)} sx={{ background: customDefines.primaryBackground }} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"

                        >
                            {element.question}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ borderLeft: "5px solid " + customDefines.emphasisColored, paddingLeft: "10px" }}>
                                <cite>{element.answer}</cite>
                            </div>
                        </AccordionDetails>
                    </Accordion>);
                })}
            </ColoredBackgroundBox >
        </React.Fragment>
    );
})

export default FAQ;