const customDefines = {
    brightBackground: "#fff",
    tintedBackground: "#f5f3f3",
    coloredBackground: "#e3dfd7",
    emphasisColored: "#aca188",
    primaryColor: "#804922",
    primaryBackground: "#fff",
    mobileWidthThreshold: 750
};

// const defaultContentWidth = "min(100%, 700px)";

export default customDefines