import { Grid } from '@mui/material';
import * as React from 'react';

import VideoComponent from '../components/videoComponent2';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';
import customDefines from '../palette';

import allOfMe from '../resources/videos/all_of_me.mp4'
import allOfMeThumbnailLow from '../resources/generated_images/thumbnail_all_of_me_low.svg'
import allOfMeThumbnailHigh from '../resources/generated_images/thumbnail_all_of_me_high.webp'

import hallelujah from '../resources/videos/hallelujah.mp4'
import hallelujahThumbnailLow from '../resources/generated_images/thumbnail_hallelujah_low.svg'
import hallelujahThumbnailHigh from '../resources/generated_images/thumbnail_hallelujah_high.webp'
import insWasserFaelltEinStein from '../resources/videos/ins_wasser_faellt_ein_stein.mp4'
import insWasserFaelltEinSteinThumbnailLow from '../resources/generated_images/thumbnail_ins_wasser_faellt_ein_stein_low.svg'
import insWasserFaelltEinSteinThumbnailHigh from '../resources/generated_images/thumbnail_ins_wasser_faellt_ein_stein_high.webp'
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useState } from 'react';

const videos = [
    [allOfMe, "All of me", "John Legend", allOfMeThumbnailLow, allOfMeThumbnailHigh],
    [hallelujah, "Hallelujah", "Alexandra Burke", hallelujahThumbnailLow, hallelujahThumbnailHigh],
    [insWasserFaelltEinStein, "Ins Wasser fällt ein Stein", "", insWasserFaelltEinSteinThumbnailLow, insWasserFaelltEinSteinThumbnailHigh]
]

function VideoList() {
    const [currentVideo, setCurrentVideo] = useState(null);

    const { width } = useWindowDimensions();
    const isMobile = width < customDefines.mobileWidthThreshold;

    return <ColoredBackgroundBox color={customDefines.brightBackground}>
        <Grid container spacing={2}>
            {videos.map((v, i) => {
                const [video, title, interpret, thumbnailLow, thumbnailHigh] = v;
                const pauseForced = currentVideo !== null && currentVideo !== video;
                return (<Grid key={i} item xs={isMobile ? 12 : 4}><VideoComponent video={video} title={title} interpret={interpret} reportPlayCallback={setCurrentVideo} pauseForced={pauseForced} secondZeroThumbnailLow={thumbnailLow} secondZeroThumbnailHigh={thumbnailHigh}></VideoComponent></Grid>);
            })}
        </Grid>
        {/* <Stack spacing={1} justifyContent="space-between" alignItems="center" direction={width < 800 ? "column" : "row"}>
            {videos.map((v, i) => { return (<VideoComponent key={i} video={v}></VideoComponent>); })}
        </Stack> */}
    </ColoredBackgroundBox >
}

export default VideoList;