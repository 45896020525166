import * as React from 'react';
import customDefines from '../palette';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';
import { Stack, Button } from '@mui/material';

import contact_low from "../resources/generated_images/contact_low.svg"
import contact_high from "../resources/generated_images/contact_high.webp"

import contact_round_low from "../resources/generated_images/contact_round_low.svg"
import contact_round_high from "../resources/generated_images/contact_round_high.webp"

import LowHighResImage from '../components/lowHighResImage';
import useWindowDimensions from '../hooks/useWindowDimensions';
import CenteredRoundedLowHighResImage from '../components/centeredRoundedImage';

function Contact({ contactClickedHandler }) {
    const { width } = useWindowDimensions();
    const mobileView = width < customDefines.mobileWidthThreshold

    function heading() {
        return <h2 style={{ paddingTop: "10px", marginTop: "10px" }}>Unsere musikalische Reise beginnt</h2>
    }

    return (
        <ColoredBackgroundBox color={customDefines.tintedBackground}>
            {!mobileView ? heading() : <></>}
            <Stack spacing={0} direction={mobileView ? "column" : "row"}>
                {mobileView ?
                    <CenteredRoundedLowHighResImage lowResSrc={contact_round_low} highResSrc={contact_round_high} alt="Diana mit ihrer Gitarre" />
                    :
                    <LowHighResImage sx={{ maxWidth: "100%" }} lowResSrc={contact_low} highResSrc={contact_high} alt="Diana mit ihrer Gitarre" />
                }
                <div>
                    {mobileView ? heading() : <></>}
                    <div style={{ paddingLeft: !mobileView ? "5vw" : "0px", width: "100%", textAlign: "center", margin: "0px auto" }}>
                        <p>
                            Du hast allgemeine Fragen? Möchtest wissen ob in meinem Terminkalender noch ein Plätzchen frei ist und was ein Auftritt kostet?
                        </p>
                        <p style={{ paddingBottom: "10px" }}>
                            Schreib mir gerne und ich melde mich schnellstmöglich bei dir zurück.
                        </p>
                        <Button variant="outlined" onClick={contactClickedHandler}>Unverbindlich anfragen</Button>
                    </div>
                </div>
            </Stack>
        </ColoredBackgroundBox >
    );
}

export default Contact;