import * as React from "react"
import customDefines from "../palette";
import RoundedLowHighResImage from "./roundedLowHighResImage";

function CenteredRoundedLowHighResImage({ lowResSrc, highResSrc, alt, borderColor = customDefines.emphasisColored, borderWidth = "7px", width = "max(33%, 155px)" }) {
    const style = {
        textAlign: "center",
        margin: "auto",
        width: width
    };

    return (
        <div style={style}>
            <RoundedLowHighResImage lowResSrc={lowResSrc} highResSrc={highResSrc} alt={alt} borderColor={borderColor} borderWidth={borderWidth}></RoundedLowHighResImage>
        </div>
    );
}

export default CenteredRoundedLowHighResImage;