import './App.css';

import * as React from 'react';
import { useState } from 'react';
import Stack from "@mui/material/Stack"
import Header from "./page_elements/header.js"
import WelcomeImage from "./page_elements/welcomeImage.js"
import WelcomeText from './page_elements/welcomeText';
import Introduction from './page_elements/introduction';
import Occasions from './page_elements/occasions';
import Samples from './page_elements/samples';
import ContactButton from './page_elements/contactButton';
import CustomSongs from './page_elements/customSongs.js';
import Repertoire from './page_elements/repertoire.js';
import Contact from './page_elements/contact.js';
import References from './page_elements/references.js';
import Footer from './page_elements/footer.js';

import VideoList from './page_elements/videoList';
import FAQ from './page_elements/faq';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import customDefines from './palette.js';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: customDefines.primaryColor,
      },
    },
  });

  const [contactButtonOpen, setContactButtonOpen] = useState(false);

  const contactButtonAnimation = () => {
    setContactButtonOpen(false);
    setTimeout(() => setContactButtonOpen(true), 200);
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Header></Header>
        <Stack>
          <WelcomeImage></WelcomeImage>
          <WelcomeText></WelcomeText>
          <Introduction></Introduction>
          <Occasions></Occasions>
          <Samples></Samples>
          <VideoList></VideoList>
          <CustomSongs></CustomSongs>
          <Repertoire></Repertoire>
          <FAQ></FAQ>
          <References></References>
          <Contact contactClickedHandler={contactButtonAnimation}></Contact>
          <Footer contactClickedHandler={contactButtonAnimation}></Footer>
        </Stack>
        <ContactButton open={contactButtonOpen} setOpen={setContactButtonOpen}></ContactButton>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
