import * as React from 'react';
import customDefines from '../palette';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';
import { Grid, Link } from "@mui/material"
import useWindowDimensions from '../hooks/useWindowDimensions';

// Icons
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import StarsIcon from '@mui/icons-material/Stars';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';

function Footer({ contactClickedHandler }) {
    const { width } = useWindowDimensions();
    const mobileView = width < customDefines.mobileWidthThreshold

    return (
        <ColoredBackgroundBox color={customDefines.brightBackground}>
            <Grid container justifyContent='space-evenly'>
                <Grid item sx={{ textAlign: "center" }} xs={mobileView ? 12 : 3}><span>© Diana Sonntag</span> </Grid>
                <Grid item sx={{ textAlign: "center" }} xs={mobileView ? 4 : 3}><a className="custom-link" href="/datenschutz">Datenschutz</a> </Grid>
                <Grid item sx={{ textAlign: "center" }} xs={mobileView ? 4 : 3}><a className="custom-link" href="/impressum">Impressum</a> </Grid>
                <Grid item sx={{ textAlign: "center" }} xs={mobileView ? 4 : 3}><a className="custom-link" onClick={() => { contactClickedHandler(); return false; }}>Kontakt</a> </Grid>
            </Grid>

            <div style={{ paddingTop: "30px", width: "min(250px, max(50%, 180px))", margin: "0px auto", color: customDefines.primaryColor, fontSize: "0.65em" }}>
                <Grid container justifyContent='space-between'>
                    <Link href="https://api.whatsapp.com/send?phone=4915143270850"><WhatsAppIcon sx={{ fontSize: "2em" }} /></Link>
                    <Link href="mailto:info@diana-sonntag.de?subject=Anfrage&body=Bitte%20die%20nachfolgenden%20Infos%20wenn%20m%C3%B6glich%20ausf%C3%BCllen.%0D%0A%0D%0AAnlass%3A%0D%0ADatum%3A%0D%0AUhrzeit%3A%0D%0APLZ%2C%20Ort%3A%0D%0A%0D%0A%0D%0AHallo%20Diana%2C%0D%0A%0D%0Awir%20w%C3%BCrden%20uns%20gerne%20f%C3%BCr%20die%20oben%20genannten%20Daten%20ein%20unverbindliches%20Angebot%20von%20dir%20erstellen%20lassen.%20Wir%20freuen%20uns%20von%20dir%20zu%20h%C3%B6ren.%0D%0A%0D%0AViele%20Gr%C3%BC%C3%9Fe%0D%0ANAME"><EmailIcon sx={{ fontSize: "2em" }} /></Link>
                    <Link href="https://www.facebook.com/dianasonntagmusik/"><FacebookIcon sx={{ fontSize: "2em" }} /></Link>
                    <Link href="https://www.youtube.com/@dianasonntagmusik5727/videos"><PlayCircleFilledRoundedIcon sx={{ fontSize: "2em" }} /></Link>
                    <Link href="https://www.eventpeppers.com/de/diana-sonntag"><StarsIcon sx={{ fontSize: "2em" }} /></Link>
                    <Link href="https://www.instagram.com/diana_sonntag_musik/?hl=de"><InstagramIcon sx={{ fontSize: "2em" }} /></Link>
                </Grid>
            </div>
        </ColoredBackgroundBox >
    );
}

export default Footer;