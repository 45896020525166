import * as React from 'react';
import { forwardRef } from 'react';
import customDefines from '../palette';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';
import { Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: customDefines.primaryColor,
    }
});

const linkLookup = {
    "Google": "https://www.google.com/search?sa=X&sca_esv=5a00d1dd542cba5a&tbm=lcl&q=Diana+Sonntag+Rezensionen&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2sjSzMLG0tLC0NDM2NzcxNzO23MDI-IpR0iUzMS9RITg_L68kMV0hKLUqNa84Mz8vNW8RK245AOwcgDlSAAAA&rldimm=2968499899637747639&hl=de-DE&ved=2ahUKEwiDpvzckPWIAxVzhv0HHQmMCqsQ9fQKegQIQhAF#lkt=LocalPoiReviews",
    "Eventpeppers": "https://www.eventpeppers.com/de/diana-sonntag/reviews"
};

const references = [
    {
        rating: 5,
        name: "Madlen O.",
        occassion: "Kirchliche Hochzeit",
        text: "Diana hat unsere kirchliche Hochzeit begleitet und wir waren so emotional berührt ihre Stimme ist einfach einzigartig. So viele Gäste haben uns auf Sie angesprochen alle waren begeistert. Auch der erste Kontakt war mega sympathisch. Diana ist auf alle unsere Wünsche eingegangen und hat uns immer kurze Aufnahmen geschickt von den Liedern weil wir uns nicht entscheiden konnten. Wir können Diana von Herzen weiterempfehlen.",
        original: "Google",
        link: linkLookup["Google"]
    },
    {
        rating: 5,
        name: "Sabrina",
        occassion: "Kirchliche Hochzeit",
        original: "Eventpeppers",
        text: "Diana hat unsere kirchliche Trauung musikalisch begleitet und uns zu 100% überzeugt. Sie singt mit so viel Gefühl und hat uns mit ihrer Stimme sehr berührt. Auch alles um die Veranstaltung herum war professionell, Auf- und Abbau alles eigenständig und zuverlässig. Von unseren Gästen wurden wir mehrmals auf die tolle Sängerin angesprochen. Die Kommunikation war immer super freundlich & herzlich. Wir können Diana aus voller Überzeugung weiterempfehlen!",
        link: linkLookup["Eventpeppers"]
    },
    {
        rating: 5,
        name: "Katharina",
        occassion: "Taufe",
        original: "Google",
        text: "Diana hat mit ihrer wunderschönen Stimme die Taufe unseres Sohnes begleitet und zu einem ganz besonderen Tag für uns gemacht. Nicht nur wir hatten Gänsehaut und Tränen in den Augen unsere Gäste waren ebenfalls alle total begeistert von ihr. Wir können Diana zu 1000% weiterempfehlen, wahnsinnig tolle Stimme, super netter Kontakt, Ablauf, Planung usw. alles unkompliziert. Danke dass du bereits das zweite mal für uns gesungen hast! Wir freuen uns schon aufs nächste Mal.",
        link: linkLookup["Google"]
    },
    {
        rating: 5,
        name: "Michael S.",
        occassion: "Standesamtliche und kirchliche Hochzeit",
        original: "Google",
        text: "Ein riesen großes Danke an dich Diana. Du hast sowohl unsere standesamtliche als auch unsere kirchliche Hochzeit zu einem einzigartigen Erlebnis gemacht. Für uns gibt es keine Alternative. Unsere ganze Hochzeitsgesellschaft wurde von deiner Musik verzaubert. Es gab nur positives Feedback.",
        link: linkLookup["Google"]
    },
    {
        rating: 5,
        name: "Timo & Lisa S.",
        occassion: "Kirchliche Hochzeit",
        original: "Eventpeppers",
        text: "Alles in allem waren wir mehr als 100%-ig zufrieden mit Dianas Auftritt. Mit ihrer unkomplizierten und äußerst freundlichen Art hat Sie uns die Planung schon sehr einfach gemacht, und uns dann in der Kirche vom Hocker gesungen :D Sie hat eine tolle, kraftvolle Stimme und die Gäste schwärmten noch den ganzen Tag lang von diesem Auftritt. Vielen lieben Dank Diana!",
        link: linkLookup["Eventpeppers"]
    },
    {
        rating: 5,
        name: "Ute",
        occassion: "Taufe",
        original: "Eventpeppers",
        text: "Wir waren sehr zufrieden und hatten eine schöne Taufe 😊 wir würden sie jederzeit wieder buchen. Man ist gleich etwas traurig wenn das Lied zuende ist, weil man noch ewig weiter zuhören hätte können.",
        link: linkLookup["Eventpeppers"]
    },
    {
        rating: 5,
        name: "Christina F.",
        occassion: "Taufe",
        original: "Google",
        text: "Die liebe Diana hat unsere Tauffeier musikalisch begleitet. Die Gäste waren begeistert von ihr und ich bekam Gänsehaut als sie meinen Wunschtitel gesungen hat. Unser Pfarrer meinte sie sollte eine CD aufnehmen 😁 da bin ich ganz seiner Meinung!",
        link: linkLookup["Google"]
    }
]

function Experience({ entry }) {
    const style = {
        frame: {
            border: "solid 3px " + customDefines.emphasisColored,
            width: "min(90%, calc(100% - 70px))",
            margin: "auto",
            padding: "20px",
            textAlign: "center",
            boxSizing: "border-box"
        },
    }
    return (
        <div style={style.frame}>
            <div><StyledRating value={entry.rating} readOnly></StyledRating></div>
            <div><b>{entry.name}</b></div>
            <div>{entry.occassion}</div>
            <p>{entry.text}</p>
            {/* <div>{entry.original}</div> */}
            <a href={entry.link} target="_blank" rel="noreferrer">zu allen {entry.original} Bewertungen</a>
            {/* <h4></h4> */}
        </div>
    );
}

const References = forwardRef(function References(props, ref) {
    // const indicators = (index) => (<div style={{ width: "15px", height: "15px", borderRadius: "50%", background: "#000" }}>{ }</div>);
    return (
        <React.Fragment>
            <div id="rezessionen" ref={ref} style={{ position: "relative", top: "-50px" }}></div>
            <ColoredBackgroundBox color={customDefines.brightBackground} style={{ padding: "20px" }}>
                <Slide indicators={true} transitionDuration={600} autoplay={false} easing="cubic-out">
                    {references.map((entry) => <Experience key={entry.name} entry={entry}></Experience>)}
                </Slide>
            </ColoredBackgroundBox >
        </React.Fragment>
    );
})

export default References;