import * as React from "react"

function FramedBox({ color, children }) {
    const styles = {
        wrapper: {
            border: "15px solid " + color,
        }
    }
    return (
        <div style={styles.wrapper}>
            {children}
        </div>
    );
}

export default FramedBox;