import * as React from 'react';
import customDefines from '../palette';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';
import FramedImage from '../components/framedImage';
import { Stack } from "@mui/material"
import useWindowDimensions from '../hooks/useWindowDimensions';
import Link from '@mui/material/Link';
import repertoirePDF from "../resources/repertoire_diana.pdf"

import repertoire_high from '../resources/generated_images/repertoire_high.webp'
import repertoire_low from '../resources/generated_images/repertoire_low.svg'

import instagram_high from '../resources/generated_images/instagram_high.webp'
import instagram_low from '../resources/generated_images/instagram_low.svg'

import occasion_party_high from '../resources/generated_images/occasion_party_high.webp'
import occasion_party_low from '../resources/generated_images/occasion_party_low.svg'
function Repertoire() {
    const { width } = useWindowDimensions();
    const mobileView = width < customDefines.mobileWidthThreshold
    return (
        <ColoredBackgroundBox color={customDefines.brightBackground}>
            <Stack spacing={2} direction={mobileView ? "column" : "row"} style={{ alignItems: mobileView ? "center" : "normal", justifyContent: "space-between" }} >
                <FramedImage mobileView={mobileView} low={repertoire_low} high={repertoire_high} alt="Repertoire" captionSection="Repertoire" link={repertoirePDF} linkText="Download als PDF...">
                    <Link href={repertoirePDF} target='_blank'>Hier</Link> findest du mein aktuelles Repertoire für Hochzeiten und Taufen
                </FramedImage>
                <FramedImage mobileView={mobileView} low={occasion_party_low} high={occasion_party_high} alt="Feier als Duo Pluspunkt" captionSection="Empfang, Feier, ..." captionText="" link="https://pluspunkt-musik.de" linkText="zur Website...">
                    Tolle Klänge im Doppelpack mit unserem Duo <Link href="https://pluspunkt-musik.de" target='_blank'>Pluspunkt</Link>
                </FramedImage>
                <FramedImage mobileView={mobileView} low={instagram_low} high={instagram_high} alt="Instagram" captionSection="Instagram" link="https://www.instagram.com/diana_sonntag_musik/?hl=de" linkText="zu Instagram...">
                    Aktuelle Hörproben und Bilder <Link href="https://www.instagram.com/diana_sonntag_musik/?hl=de" target='_blank'>diana_sonntag_musik</Link>
                </FramedImage>
            </Stack>
        </ColoredBackgroundBox >
    );
}

export default Repertoire;