import * as React from 'react'
import { useState } from 'react';
import { Children } from 'react';

// import useImageOnLoad from '../hooks/useImageOnLoad'

function LowHighResImage({ width = '100%', height = '100%', lowResSrc, highResSrc, alt, sx, children, lazy = true }) {
    // const { handleImageOnLoad, transitionStyles } = useImageOnLoad();
    const [lowVisible, setLowVisible] = useState(true);

    const styles = {
        wrapper: {
            position: 'relative',
            height,
            display: "flex",
            width,
            justifyContent: "center"
        },
        image: {
            width: '100%',
            ...sx
        },
        hidden: {
            width: '0px',
            height: '0px',
        },
    };

    return (
        <div style={styles.wrapper}>
            {lowVisible ?
                <img style={styles.image} src={lowResSrc} alt={alt} /> : null
            }
            <img style={lowVisible ? styles.hidden : styles.image} alt={alt} src={highResSrc} loading={lazy ? "lazy" : undefined} onLoad={() => setLowVisible(false)} />
            <div style={{ position: "absolute", bottom: "50%", width: "100%" }}>
                {Children.map(children, child =>
                    <div>
                        {child}
                    </div>
                )}
            </div>
        </div>
    )
}

export default LowHighResImage;