import * as React from 'react';
import { forwardRef } from 'react';
import { Stack } from "@mui/material"

import customDefines from '../palette';

import ColoredBackgroundBox from '../components/coloredBackgroundBox';
import useWindowDimensions from '../hooks/useWindowDimensions';

import LowHighResImage from '../components/lowHighResImage';
import CenteredRoundedLowHighResImage from '../components/centeredRoundedImage';

import introduction_low from "../resources/generated_images/introduction_low.svg"
import introduction_high from "../resources/generated_images/introduction_high.webp"

import introductionRound_low from "../resources/generated_images/introduction_round_low.svg"
import introductionRound_high from "../resources/generated_images/introduction_round_high.webp"


function IntroductionText() {
    return <p>
        Hi, schön dass du da bist! Ich bin Diana, Sängerin im Raum Bayern. Schon seit meinem 15. Lebensjahr singe ich auf Hochzeiten und Taufen.
    </p>

}

function IntroductionOutroText() {
    return <React.Fragment>
        <p>
            Meine Familie und Freunde beschreiben mich als offenen und herzlichen Menschen. Privat liebe ich es kreativ zu sein; male und bastle sehr gerne.
        </p>
        <p>
            Ich freue mich euch kennen zu lernen.
        </p>
    </React.Fragment>
}

function IntroductionCite() {
    const style = {
        borderLeft: "7px solid " + customDefines.emphasisColored,
        paddingLeft: "15px",
        margin: "15px 10px 15px 10px"
    };
    return (
        <div style={style}>
            <cite >
                Ich bin dankbar, dass ich mit meiner Stimme diese unvergesslichen Lebensereignisse mitgestalten darf. Besonders am Herzen liegt mir die individuelle Gestaltung nach euren Wünschen.
            </cite>
        </div>
    );
}

function DesktopIntroduction() {
    return <Stack direction="row">
        <div style={{ paddingRight: "15px" }}>
            <IntroductionText />
            <IntroductionCite />
            <IntroductionOutroText />
        </div>
        <LowHighResImage lowResSrc={introduction_low} highResSrc={introduction_high} alt="Diana mit ihrer Gitarre" />
    </Stack>
}

function MobileIntroduction() {
    return <Stack direction="column">
        <CenteredRoundedLowHighResImage lowResSrc={introductionRound_low} highResSrc={introductionRound_high} alt="Diana mit ihrer Gitarre" color="white" />
        <IntroductionText></IntroductionText>
        <IntroductionCite />
        <IntroductionOutroText />
    </Stack>
}

const Introduction = forwardRef(function Introduction(props, ref) {
    const { width } = useWindowDimensions();
    const mobileView = width < customDefines.mobileWidthThreshold
    return (
        <React.Fragment>
            <div id="ueber_mich" ref={ref} style={{ position: "relative", top: "-50px" }}></div>
            <ColoredBackgroundBox color={customDefines.coloredBackground} contentWidth={"min("}>
                <h2>Das bin ich</h2>
                {mobileView ? <MobileIntroduction /> : <DesktopIntroduction />}
            </ColoredBackgroundBox>
        </React.Fragment>
    );
})

export default Introduction;