import * as React from 'react';

import welcomeImage_low from "../resources/generated_images/welcome_low.svg"
import welcomeImage_high from "../resources/generated_images/welcome_high.webp"
import LowHighResImage from '../components/lowHighResImage';

function WelcomeImage() {
    return (
        <LowHighResImage lowResSrc={welcomeImage_low} highResSrc={welcomeImage_high} alt="Diana lachend und sitzend mit ihrer Gitarre" lazy={false}>
            {/* <div style={{ position: "absolute", top: "-20px", width: "100%", background: "#00000033", padding: "20px", textAlign: "center" }}>
                SängerinDiana.svg
            </div> */}
            {/* <div style={{ backdropFilter: "blur(5px)", position: "absolute", top: "-40px", width: "100%", background: "#ffffff55", padding: "20px", textAlign: "center" }}>
                <h2>Sängerin Diana im Raum Bayern</h2>
            </div> */}
        </LowHighResImage>
    );
}

export default WelcomeImage;