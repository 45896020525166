import * as React from 'react';
import { useState } from 'react';
import FramedBox from '../components/framedBox';
import customDefines from '../palette';
import LowHighResImage from '../components/lowHighResImage';

function WrapWithAIfLinkSet({ link, linkText, children }) {
    const [hovering, setHovering] = useState(false);
    if (link) {
        return <a href={link} target="_blank" rel="noreferrer">
            <div style={{ position: "relative" }}>
                {children}
                <div style={{ position: "absolute", top: "0px", left: "0px", height: "100%", width: "100%", padding: "20%" }} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
                    {
                        hovering ?
                            <div style={{ display: "flex", height: "100%", width: "100%", borderRadius: "50%", background: customDefines.emphasisColored + 'bb', justifyContent: "center", alignItems: "center", margin: "auto" }}>
                                <div style={{ textDecoration: "none", color: "white", fontSize: "1.15em" }}>
                                    {linkText}
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        </a>
    }
    else {
        return <div>{children}</div>;
    }
}

function FramedImage({ low, high, alt, captionSection, linkText, mobileView, link, children }) {
    const background_color = customDefines.emphasisColored;
    const width = mobileView ? "70%" : "100%"
    const styles = {
        wrapper: {
            top: "0%",
            display: "block",
            textAlign: "center",
            position: "relative",
            width: width
        },
        captionSection: {
            fontSize: "120%",
            fontStyle: "italic",
            padding: "10px 0px"
        },
    }


    return <div style={styles.wrapper}>
        <WrapWithAIfLinkSet link={link} linkText={linkText}>
            <FramedBox color={background_color}>
                <LowHighResImage lowResSrc={low} highResSrc={high} alt={alt}></LowHighResImage>
            </FramedBox>
        </WrapWithAIfLinkSet>
        <figcaption>
            <div style={styles.captionSection}>{captionSection}</div>
            <div>{children}</div>
        </figcaption>
    </div>
}

export default FramedImage;
