import * as React from "react"
import LowHighResImage from "./lowHighResImage";
import customDefines from "../palette";

function RoundedLowHighResImage({ lowResSrc, highResSrc, alt, borderColor = customDefines.emphasisColored, borderWidth = "7px" }) {
    return (
        <LowHighResImage lowResSrc={lowResSrc} highResSrc={highResSrc} alt={alt} sx={{ borderRadius: "50%", borderColor: borderColor, borderStyle: "solid", borderWidth: borderWidth }}></LowHighResImage>
    );
}

export default RoundedLowHighResImage;