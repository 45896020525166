import * as React from 'react';
import { useRef, useState, forwardRef } from 'react';

import { Stack, Divider } from "@mui/material"

import customDefines from '../palette';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';
import { IconButton } from "@mui/material"
import { PlayArrowRounded } from '@mui/icons-material';
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded';
import useWindowDimensions from '../hooks/useWindowDimensions';

import seiAufDieserWeltWillkommen from '../resources/snippets/sei_auf_dieser_welt_willkommen_pluspunkt.mp3';
import bergwerk from '../resources/snippets/bergwerk_pluspunkt.mp3';
import billieJean from '../resources/snippets/billie_jean_pluspunkt.mp3';
import iNeedADollar from '../resources/snippets/i_need_a_dollar_pluspunkt.mp3';
import teenageDirtbag from '../resources/snippets/teenage_dirtbag_pluspunkt.mp3';

const styles = {
    background: {
        background: customDefines.primaryBackground,
        padding: "10px"
    },
    interpret: {
        color: "darkgray",
        fontSize: "85%",
    }
}

function toMinuteSeconds(time) {
    return Math.floor(time / 60) + ':' + ('' + (Math.round(time) % 60)).padStart(2, '0')
}

function DesktopSample({ title, interpret, sample, reportPlayCallback, pauseForced }) {
    const [playing, setPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const desktop_styles = {
        title: {
            paddingLeft: "10px"
        },
        interpret: {
            ...styles.interpret
        }
    }

    const myAudio = useRef()

    function togglePlay() {
        if (myAudio.current === null) {
            return
        }
        if (myAudio.current.paused) {
            myAudio.current.play();
            reportPlayCallback(sample)
        }
        else {
            myAudio.current.pause();
        }
        setPlaying(!myAudio.current.paused);
    }

    if (pauseForced && myAudio.current != null && playing) {
        setPlaying(false);
        myAudio.current.pause();
    }

    return <div style={styles.background}><Stack spacing={2} direction="row" justifyContent={"space-between"} alignItems={"center"} >
        <Stack spacing={1} direction="row" alignItems={"center"}>
            <span>
                <IconButton size="large" aria-label="menu" sx={{ padding: "10px" }} onClick={togglePlay}>
                    {!playing ?
                        <PlayArrowRounded />
                        :
                        <PauseCircleRoundedIcon />
                    }
                    <audio type='audio' preload="metadata" onEnded={() => setPlaying(false)} onLoadedMetadata={() => setDuration(myAudio.current.duration)} ref={myAudio} preload="auto" autobuffer="true"><source src={sample} />Your browser does not support the audio element.</audio>
                </IconButton>
            </span>
            <Divider orientation="vertical" flexItem></Divider>
            <span style={desktop_styles.title}>{title}</span>
            <span style={desktop_styles.interpret}>{interpret}</span>
        </Stack>
        <Stack>
            {toMinuteSeconds(duration)}
        </Stack>
    </Stack >
    </div >
}

function MobileSample({ title, interpret, sample, reportPlayCallback, pauseForced }) {
    const [playing, setPlaying] = useState(false);
    const [duration, setDuration] = useState(0);

    const mobile_styles = {
        title: {
            // color: "red",
            textAlign: "center"
        },
        interpret: {
            ...styles.interpret,
            textAlign: "center"
        },
    }
    const myAudio = useRef()

    function togglePlay() {
        if (myAudio.current === null) {
            return
        }
        if (myAudio.current.paused) {
            myAudio.current.play();
            reportPlayCallback(sample)
        }
        else {
            myAudio.current.pause();
        }
        setPlaying(!myAudio.current.paused);
    }

    if (pauseForced && myAudio.current != null && playing) {
        setPlaying(false);
        myAudio.current.pause();
    }

    return <div style={styles.background}>
        <Stack spacing={1} direction="row" justifyContent={"flex-start"} alignItems={"center"} divider={<Divider orientation="vertical" flexItem></Divider>}>
            <Stack spacing={1} direction="column" alignItems={"center"}>
                <span>
                    <IconButton size="large" aria-label="menu" sx={{ padding: "12px" }} onClick={togglePlay}>
                        {!playing ?
                            <PlayArrowRounded />
                            :
                            <PauseCircleRoundedIcon />
                        }
                        <audio onEnded={() => setPlaying(false)} type='audio' onLoadedMetadata={() => setDuration(myAudio.current.duration)} ref={myAudio} preload="auto" autobuffer="true"><source src={sample} />Your browser does not support the audio element.</audio>
                    </IconButton>
                </span>
            </Stack>
            <div style={{ margin: "auto", paddingLeft: "10px" }}>
                <Stack spacing={1} direction="column" alignItems={"center"} justifyContent={"flex-start"}>
                    <span style={mobile_styles.title}>{title}</span>
                    <span style={mobile_styles.interpret}>{interpret}</span>
                </Stack>
            </div>
            <div>{toMinuteSeconds(duration)}</div>
        </Stack >
    </div>
}


const SampleEntries = [
    ["SEI AUF DIESER WELT WILLKOMMEN", "JULIA MEITINGER", seiAufDieserWeltWillkommen],
    ["TEENAGE DIRTBAG", "WHEATUS", teenageDirtbag],
    ["BERGWERK", "RAINHARD FENDRICH", bergwerk],
    ["I NEED A DOLLAR", "ALOE BLACC", iNeedADollar],
    ["BILLIE JEAN", "MICHAEL JACKSON", billieJean]
];

const Samples = forwardRef(function Samples(props, ref) {
    const { width } = useWindowDimensions();
    const mobileView = width < customDefines.mobileWidthThreshold
    const [currentSample, setCurrentSample] = useState(null);

    function reportPlay(sample) {
        setCurrentSample(sample)
    }

    return (
        <React.Fragment>
            <div id="hoerproben" ref={ref} style={{ position: "relative", top: "-50px" }}></div>
            <ColoredBackgroundBox color={customDefines.coloredBackground}>
                <h2>Hörproben</h2>
                <Stack spacing={1}>
                    {
                        mobileView ?
                            SampleEntries.map((el) => {
                                const [title, interpret, sample] = el;
                                const pauseForced = currentSample !== null && currentSample !== sample;
                                return (<MobileSample key={title + interpret} title={title} interpret={interpret} sample={sample} reportPlayCallback={reportPlay} pauseForced={pauseForced}></MobileSample>)
                            })
                            :
                            SampleEntries.map((el) => {
                                const [title, interpret, sample] = el;
                                const pauseForced = currentSample !== null && currentSample !== sample;
                                return (<DesktopSample key={title + interpret} title={title} interpret={interpret} sample={sample} reportPlayCallback={reportPlay} pauseForced={pauseForced}></DesktopSample>)
                            })
                    }
                </Stack>
            </ColoredBackgroundBox >
        </React.Fragment>
    );
})

export default Samples;