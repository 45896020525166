import * as React from 'react';

import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CloseIcon from '@mui/icons-material/Close';
import customDefines from '../palette';

function ContactButton({ open, setOpen }) {
    const styles = {
        a: {
            color: "inherit",
            textDecoration: "inherit",
            marginTop: "6px"
        }
    };

    const closeSpeedDial = (event) => {
        setOpen(false);
    };

    const actions = [
        { icon: <a style={styles.a} target="new" onClick={closeSpeedDial} href="https://api.whatsapp.com/send?phone=4915143270850"><WhatsAppIcon /></a>, name: 'WhatsApp' },
        { icon: <a style={styles.a} target="new" onClick={closeSpeedDial} href="mailto:info@diana-sonntag.de?subject=Auftrittsanfrage%20Diana%20Sonntag&body=Bitte%20die%20nachfolgenden%20Infos%20wenn%20m%C3%B6glich%20ausf%C3%BCllen.%0D%0A%0D%0AAnlass%3A%0D%0ADatum%3A%0D%0AUhrzeit%3A%0D%0APLZ%2C%20Ort%3A%0D%0A%0D%0A%0D%0AHallo%20Diana%2C%0D%0A%0D%0Awir%20w%C3%BCrden%20uns%20gerne%20f%C3%BCr%20die%20oben%20genannten%20Daten%20ein%20unverbindliches%20Angebot%20von%20dir%20erstellen%20lassen.%20Wir%20freuen%20uns%20von%20dir%20zu%20h%C3%B6ren.%0D%0A%0D%0AViele%20Gr%C3%BC%C3%9Fe%0D%0ANAME"><EmailIcon /></a>, name: 'Email' },
    ];

    return <SpeedDial
        open={open}
        onClick={(event) => setOpen(!open)}
        ariaLabel="SpeedDial basic example"
        sx={{ color: customDefines.emphasisColored, position: 'fixed', bottom: "20px", right: "20px" }}
        icon={
            <SpeedDialIcon icon={<ContactSupportIcon />}
                openIcon={<CloseIcon />} />
        }
    >
        {actions.map((action) => (
            <SpeedDialAction sx={{ width: "50px", height: "50px" }}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
            />
        ))}
    </ SpeedDial>;
}

export default ContactButton;