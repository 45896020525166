import * as React from 'react';
import customDefines from '../palette';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';

import decoration_botleft from "../decoration_leaves_botleft.svg"
import decoration_topright from "../decoration_leaves_topright.svg"

function CustomSongs() {
    return (
        <div style={{ position: "relative" }}>
            <img style={{ position: "absolute", bottom: "0px", left: "0px", width: "25%", maxHeight: "90%" }} alt="leave decoration" src={decoration_botleft} />
            <img style={{ position: "absolute", top: "0px", right: "0px", width: "25%", maxHeight: "90%" }} alt="leave decoration" src={decoration_topright} />
            <ColoredBackgroundBox color={customDefines.tintedBackground}>
                <div style={{ zIndex: "10", position: "relative" }}>
                    <h2>Wunschlieder</h2>
                    <div style={{ width: "70%", textAlign: "center", justifyContent: "center", margin: "0px auto" }}>
                        <p>Das erste Date, der erste Kuss oder der Heiratsantrag, die Geburt des eigenen Kindes oder das erste Lächeln:<br />
                            Besondere Momente in unserem Leben verbinden wir oft mit einem ganz bestimmten Lied. Als professionelle Sängerin setze ich eure persönlichen Liedwünsche um.</p>
                        <p>
                            Nicht die richtige Hörprobe gefunden?
                            Ich studiere und singe für euch gerne individuell euren Wunschsong ein.
                        </p>
                    </div>
                </div>
            </ColoredBackgroundBox >
        </div >
    );
}

export default CustomSongs;