import * as React from 'react';

import { Stack } from "@mui/material"
import { Divider } from '@mui/material';

import customDefines from '../palette';
import ColoredBackgroundBox from '../components/coloredBackgroundBox';

function WelcomeText() {
    const styles = {
        wrapper: {
            textAlign: "center"
        },
        locations: {
            fontWeight: "bold"
        }
    };

    const locations = ["Nördlingen", "Donauwörth", "Dillingen", "Günzburg", "Augsburg", "Aichach-Friedberg", "Neuburg", "Schrobenhausen", "Ingolstadt", "Eichstätt", "Weißenburg", "Gunzenhausen", "Ansbach"]

    return (
        <React.Fragment>
            <ColoredBackgroundBox color={customDefines.brightBackground}>
                <Stack style={styles.wrapper}>
                    <h2>Sängerin Diana im Raum Bayern</h2>
                    <h3>Willkommen</h3>
                    <p>Ihr sucht für die Taufe eures kleinen Engels eine Sängerin, wollt dass eure Hochzeit und eure Trauung einzigartig und unvergesslich wird? Dann seid ihr bei mir genau richtig!</p>
                    <Stack style={{ display: "none" }} justifyContent="center" alignItems="center" spacing={1} sx={{ flexWrap: 'wrap', gap: 1, padding: "0 10px", margin: "20px 0" }} divider={<Divider orientation="vertical" flexItem />} direction="row">
                        {
                            locations.map((location, i) => { return <span style={styles.locations} key={"key" + location}>{location}</span> })
                        }
                    </Stack>
                </Stack>
            </ColoredBackgroundBox >
        </React.Fragment>
    );
};

export default WelcomeText;